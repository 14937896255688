import './styles.scss';

import { CustomPagination } from '@components/modules/pagination';
import { DATE_FORMAT, PAGE_SIZE } from '@constants/index.constant';
import { IRevenueByPartner } from '@interfaces/revenue-by-partner';
import { getDefaultSortOrder, mapAntdSorterToCrudSorting } from '@refinedev/antd';
import { useTable } from '@refinedev/core';
import { convertPrice } from '@utils/resource';
import { DatePicker, Table, TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { DataProviderNameEnum } from 'dataProvider';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const REPORT_RESOURCE_URL = 'v1/report';
const { RangePicker } = DatePicker;
const DEFAULT_RANGE_DATE: [dayjs.Dayjs, dayjs.Dayjs] = [
    dayjs(dayjs().startOf('month'), DATE_FORMAT),
    dayjs(dayjs(), DATE_FORMAT),
];

export const IncomeCrmIndex: React.FC = () => {
    const { t: translate } = useTranslation(['common']);

    const dataProviderName = DataProviderNameEnum.INTERNAL_CRM;

    const [dateRangeFilter, setDateRangeFilter] = useState(DEFAULT_RANGE_DATE);

    const {
        setFilters,
        setSorters,
        sorters,
        tableQueryResult,
        setCurrent,
        current,
        setPageSize,
        pageSize,
    } = useTable<IRevenueByPartner.IIncome>({
        dataProviderName,
        resource: `${REPORT_RESOURCE_URL}/revenue-by-partner`,
        syncWithLocation: false,
        pagination: {
            pageSize: PAGE_SIZE,
        },
        filters: {
            initial: [
                {
                    field: 'dateStart',
                    operator: 'eq',
                    value: dayjs().startOf('month').format(DATE_FORMAT),
                },
                {
                    field: 'dateEnd',
                    operator: 'eq',
                    value: dayjs().format(DATE_FORMAT),
                },
            ],
        },
    });

    const onChangeTable = (
        pagination: TablePaginationConfig,
        tableFilters: Record<
            string,
            (string | number | boolean) | (string | number | boolean)[] | null
        >,
        sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
        if (sorter && Object.keys(sorter).length > 0) {
            // Map Antd:Sorter -> refine:CrudSorting
            const crudSorting = mapAntdSorterToCrudSorting(sorter);
            setSorters(crudSorting);
        }
    };

    const ranges: any = {
        [translate('reports.rangeDate.yesterday')]: [dayjs().add(-1, 'd'), dayjs().add(-1, 'd')],

        [translate('reports.rangeDate.today')]: [dayjs(), dayjs()],

        [translate('reports.rangeDate.last7Days')]: [dayjs().add(-7, 'd'), dayjs()],

        [translate('reports.rangeDate.last14Days')]: [dayjs().add(-14, 'd'), dayjs()],

        [translate('reports.rangeDate.last30Days')]: [dayjs().add(-30, 'd'), dayjs()],

        [translate('reports.rangeDate.last90Days')]: [dayjs().add(-90, 'd'), dayjs()],

        [translate('reports.rangeDate.thisWeek')]: [dayjs().startOf('week'), dayjs()],

        [translate('reports.rangeDate.thisMonth')]: [dayjs().startOf('month'), dayjs()],

        [translate('reports.rangeDate.thisYear')]: [dayjs().startOf('year'), dayjs()],
    };
    return (
        <>
            <section className="item-list-container mb-10">
                <h3 className="title-section">
                    {translate('crm_internal.revenue.outcome_income_report')}
                </h3>
                <div className="list-header">
                    <div className="filter-wrapper mb-4">
                        <RangePicker
                            dropdownClassName="filter-by-date-range"
                            allowClear={false}
                            ranges={ranges}
                            disabledDate={(date) => +date > +dayjs()}
                            onChange={(e: any, dayString: string[]) => {
                                setDateRangeFilter([dayjs(dayString[0]), dayjs(dayString[1])]);
                                setFilters([
                                    {
                                        field: 'dateStart',
                                        operator: 'eq',
                                        value: dayjs(dayString[0]).format(DATE_FORMAT),
                                    },
                                    {
                                        field: 'dateEnd',
                                        operator: 'eq',
                                        value: dayjs(dayString[1]).format(DATE_FORMAT),
                                    },
                                ]);
                            }}
                            value={dateRangeFilter}
                            getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
                            format={DATE_FORMAT}
                        />
                    </div>
                </div>

                <div className="overflow-hidden">
                    <div className="list-content table-wrapper">
                        <Table
                            dataSource={tableQueryResult.data?.data}
                            loading={tableQueryResult.isFetching}
                            onChange={onChangeTable}
                            pagination={false}
                            rowKey="id"
                            scroll={{ x: '599px', y: '100%' }}
                        >
                            <Table.Column
                                title={<>{translate('income_report.table.reseller')}</>}
                                dataIndex="name"
                                key="partner"
                                render={(text, _) => <p className="table-tbody-text">{text}</p>}
                            />

                            <Table.Column
                                sorter
                                defaultSortOrder={getDefaultSortOrder('income', sorters)}
                                title={<>{translate('income_report.table.income')}</>}
                                dataIndex="income"
                                key="income"
                                render={(text, _) => (
                                    <p className="table-tbody-text">{convertPrice(text)}</p>
                                )}
                            />
                            <Table.Column
                                sorter
                                defaultSortOrder={getDefaultSortOrder('income', sorters)}
                                title={<>{translate('income_report.table.outcome')}</>}
                                dataIndex="outcome"
                                key="outcome"
                                render={(text, _) => (
                                    <p className="table-tbody-text">{convertPrice(text)}</p>
                                )}
                            />
                        </Table>
                    </div>
                </div>

                <div className="pagination-container pt-3 pb-4 px-6">
                    <CustomPagination
                        pageSize={pageSize}
                        total={tableQueryResult?.data?.total}
                        current={current}
                        onChange={(value: number, size: number) => {
                            setCurrent(value);
                            setPageSize(size);
                        }}
                    />
                </div>
            </section>
        </>
    );
};
