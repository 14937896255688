import './styles.scss';

import { OTP_RESOURCE_NAME, ROOT_TENANT } from '@constants/index.constant';
import { QuoteStatus } from '@enums/quote-status.enum';
import { UserTypeEnum } from '@enums/user-type.enum';
import { IQuote } from '@interfaces/quote';
import { IUser } from '@interfaces/user';
import {
    BaseKey,
    useApiUrl,
    useCreate,
    useCustomMutation,
    useGetIdentity,
    useIsAuthenticated,
    useNavigation,
    useOne,
} from '@refinedev/core';
import Response from '@responses/response';
import { FONT_INTER } from '@utils/fonts';
import { formatDate, formatPrice } from '@utils/resource';
import { Button, Col, Popconfirm, Row, Spin } from 'antd';
import { TOKEN_KEY } from 'authProvider';
import { DataProviderNameEnum } from 'dataProvider';
import jsPDF from 'jspdf';
import { PayoutContextProvider } from 'pages/internal-crm/context/payout-warning';
import { useEffect, useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { maskPhone } from 'utils/string';

type QuoteAction = 'approve' | 'reject' | null;

export const QuoteCrmDetailsIndex: React.FC = () => {
    const { t } = useTranslation(['quote', 'common']);
    const { id } = useParams();

    const { goBack, push } = useNavigation();
    const { mutate } = useCustomMutation<any>();

    const { isSuccess: isAuthenticated } = useIsAuthenticated();
    const { data: userIdentity } = useGetIdentity<IUser.IUserDto>();
    const { mutate: sendOtp, isLoading: isSendingOtp } = useCreate<Response<string>>();

    const apiUrl = useApiUrl(DataProviderNameEnum.INTERNAL_CRM);
    const dataProviderName = DataProviderNameEnum.INTERNAL_CRM;

    const componentToPrint = useRef<any>();
    const [loading, setLoading] = useState(false);
    const [rejectLoading, setRejectLoading] = useState(false);

    const isReseller = userIdentity?.userType === UserTypeEnum.RESELLER;
    const isSa =
        userIdentity?.tenantId === ROOT_TENANT && userIdentity?.userType === UserTypeEnum.TENANT;

    const {
        refetch: quoteRefetch,
        data: quoteData,
        isLoading,
        error,
    } = useOne<IQuote.QuoteDetails>({
        dataProviderName,
        resource: 'v1/quotes',
        id: id as BaseKey,
        queryOptions: { enabled: false },
        errorNotification: {
            type: 'error',
            message: t('quotes.error.quote_has_expired'),
        },
    });

    let token: string | null = null;
    const tokenKey = localStorage.getItem(TOKEN_KEY);
    const urlParams = new URLSearchParams(window.location.search);
    token = urlParams.get('token');

    useEffect(() => {
        if (error) {
            const { statusCode } = error as { statusCode: number };
            if (statusCode === 404) {
                push('/404');
            }
        }
    }, [error]);

    if (isAuthenticated || token || tokenKey) {
        quoteRefetch();
    } else push('/404');

    const getPlanItemInformation = (planItem: IQuote.IPaymentPlanItem): IQuote.IPaymentPlanItem => {
        const quote = quoteData?.data;
        const result = { ...planItem };
        const quoteItem = quote?.items?.find(
            (item: IQuote.QuoteItem) => item.id === planItem.quoteItemId,
        );
        if (quoteItem) {
            result.frequency = quoteItem.frequency;
            result.quoteItemName = quoteItem.productName ?? '';
        }
        return result;
    };

    const getTotalPrice = (): number => {
        return (
            quoteData?.data?.items?.reduce(
                (total: number, item: IQuote.QuoteItem) => total + item.total,
                0,
            ) || 0
        );
    };

    const getPlanTotalPrice = (plan: IQuote.IPlan): number => {
        return (
            plan?.paymentPlanItems?.reduce(
                (total: number, item: IQuote.IPaymentPlanItem) => total + (item.total ?? 0),
                0,
            ) || 0
        );
    };

    const getPlanEarning = (plan: IQuote.IPlan): number => {
        return (
            plan?.paymentPlanItems?.reduce(
                (total: number, item: IQuote.IPaymentPlanItem) => total + (item.earning ?? 0),
                0,
            ) || 0
        );
    };

    const handleQuoteAction = (type: QuoteAction) => {
        type === 'approve' ? approve() : rejectQuote();
    };

    const rejectQuote = () => {
        setRejectLoading(true);
        mutate(
            {
                dataProviderName,
                url: `${apiUrl}/v1/quotes/${id}/reject`,
                method: 'post',
                values: {},
            },
            {
                onSuccess: goBack,
                onError: (error) => {
                    console.error(error);
                    setRejectLoading(false);
                },
            },
        );
    };

    const approve = () => {
        setLoading(true);
        mutate(
            {
                dataProviderName,
                url: `${apiUrl}/v1/quotes/${id}/approve`,
                method: 'post',
                values: {},
                successNotification: false,
                errorNotification: false,
            },
            {
                onSuccess: (data) => handleSuccessApprove(data),

                onError: (error) => {
                    console.error(error);
                    setLoading(false);
                },
            },
        );
    };

    const handleSuccessApprove = (data: any) => {
        // eslint-disable-next-line no-unsafe-optional-chaining
        const { invoiceId, paymentPlans } = isSa ? data?.data?.data : data?.data || {};

        if (!paymentPlans?.length && invoiceId) {
            push(
                isAuthenticated
                    ? `/sale/invoices/show/${invoiceId}`
                    : `/invoices/public/${invoiceId}?quoteId=${quoteData?.data?.id}&token=${token}`,
            );
        }
        setLoading(false);
    };

    const handleDownload = useReactToPrint({
        content: () => componentToPrint.current,
        copyStyles: true,
        pageStyle: `
        @media all {
            .no-print {
                display: none!important;
            }

            .no-space {
                letter-spacing: 0.01px; 
            }
        }
        `,
        print: async (printIframe: any) => {
            const document = printIframe.contentDocument;
            if (document) {
                const html = document.getElementsByTagName('html')[0];
                html.style.width = '900px';
                const doc = new jsPDF('p', 'px');
                doc.addFileToVFS('inter.ttf', FONT_INTER);
                doc.addFont('inter.ttf', 'inter', 'normal');
                doc.setFont('inter', 'normal');
                doc.html(html, {
                    html2canvas: {
                        scale: 0.5,
                    },
                    async callback(doc) {
                        await doc.save(`Quote #${quoteData?.data?.incrementId}`);
                    },
                });
            }
        },
    });

    <Button
        type="link"
        icon={<img src="/images/icons/download.svg" />}
        className="no-print mr-3"
        onClick={handleDownload}
    />;

    const getTotalEarning = (): number => {
        return (
            quoteData?.data?.items?.reduce((totalEarning, item) => {
                return totalEarning + (item.earning || 0);
            }, 0) || 0
        );
    };

    const pay = () => {
        if (quoteData?.data?.invoiceId) {
            push(
                isAuthenticated
                    ? `/sale/invoices/show/${quoteData?.data?.invoiceId}`
                    : `/invoices/public/${quoteData?.data?.invoiceId}?quoteId=${quoteData?.data?.id}&token=${token}`,
            );
        }
    };

    const payInvoiceWithPlan = (invoiceId: string) => {
        push(
            isAuthenticated
                ? `/sale/invoices/show/${invoiceId}?quoteId=${quoteData?.data?.id}`
                : `/invoices/public/${invoiceId}?quoteId=${quoteData?.data?.id}&token=${token}`,
        );
    };

    return (
        <PayoutContextProvider>
            <>
                {!isLoading && !error && (
                    <section className="block-container quote-detail" ref={componentToPrint}>
                        {/* Quote Details */}
                        <QuoteDetailsHeader
                            quote={quoteData?.data}
                            handleDownload={handleDownload}
                            componentToPrint={componentToPrint}
                            t={t}
                        />

                        {/* User Information */}
                        <UserInformation quote={quoteData?.data} t={t} />

                        {/* Item List */}
                        <ItemList
                            quote={quoteData?.data}
                            totalPrice={getTotalPrice}
                            isReseller={isReseller}
                            totalEarning={getTotalEarning}
                            t={t}
                        />

                        {/* Payment Plans */}
                        <PaymentPlans
                            quote={quoteData?.data}
                            planTotalPrice={getPlanTotalPrice}
                            planEarning={getPlanEarning}
                            payInvoiceWithPlan={payInvoiceWithPlan}
                            isAuthenticated={isAuthenticated}
                            userIdentity={userIdentity}
                            getPlanItemInformation={getPlanItemInformation}
                            isReseller={isReseller}
                            t={t}
                        />

                        {/* Terms and Conditions */}
                        {quoteData?.data?.termsAndConditions && (
                            <TermsAndConditions terms={quoteData?.data?.termsAndConditions} t={t} />
                        )}

                        {/* Actions */}
                        <QuoteActions
                            quote={quoteData?.data}
                            isAuthenticated={isAuthenticated}
                            loading={loading}
                            rejectLoading={rejectLoading}
                            isSendingOtp={isSendingOtp}
                            handleQuoteAction={handleQuoteAction}
                            pay={pay}
                            goBack={goBack}
                            userIdentity={userIdentity}
                            t={t}
                        />
                    </section>
                )}

                {(isLoading || error) && <Spin spinning={true} />}
            </>
        </PayoutContextProvider>
    );
};

// Components

const QuoteDetailsHeader = ({
    quote,
    handleDownload,
    componentToPrint,
    t,
}: {
    quote: IQuote.QuoteDetails | undefined;
    handleDownload: () => void;
    componentToPrint: any;
    t: TFunction<['quote', 'common']>;
}) => (
    <div className="block-heading">
        <div>
            <span>
                {t('quotes.heading.details')}: {quote?.incrementId}
            </span>
            <div className="date-container">
                <span className="date-title">
                    {t('quotes.fields.expected_payment_date.label')} -{' '}
                    {formatDate(quote?.date, 'MMM DD, YYYY')}
                </span>
                <span className="date-title">
                    {t('quotes.fields.dueDate.label')} -{' '}
                    {formatDate(quote?.dueDate, 'MMM DD, YYYY')}
                </span>
            </div>
        </div>
        <div className="ml-auto">
            <Button
                type="link"
                icon={<img src="/images/icons/download.svg" />}
                className="no-print mr-3"
                onClick={handleDownload}
            />
            <ReactToPrint
                copyStyles
                pageStyle={`
                    @page { margin: 0; }
                    @media all {
                        .no-print {
                            display: none!important;
                        }
                    }
                `}
                trigger={() => (
                    <Button
                        type="link"
                        icon={<img src="/images/icons/print.svg" />}
                        className="no-print"
                    />
                )}
                content={() => componentToPrint.current}
            />
        </div>
    </div>
);

const UserInformation = ({
    quote,
    t,
}: {
    quote: IQuote.QuoteDetails | undefined;
    t: TFunction<['quote', 'common']>;
}) => (
    <>
        {quote?.description && (
            <div className="details-section">
                <div className="section-header">{t('quotes.fields.description.label')}</div>
                <Row gutter={20}>
                    <Col md={24}>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: quote?.description as string,
                            }}
                        ></div>
                    </Col>
                </Row>
            </div>
        )}
        <div className="details-section">
            <Row gutter={20}>
                <Col md={12} className="user-info-col">
                    <div className="user-info">
                        <div className="info-header">{t('quotes.heading.createdBy')}</div>
                        <div className="name">{`${quote?.createdByUser?.firstName ?? ''} ${
                            quote?.createdByUser?.lastName ?? ''
                        }`}</div>
                        <div className="no-space">{quote?.createdByUser?.email ?? ''}</div>
                        <div className="no-space">
                            {quote?.createdByUser?.phone
                                ? maskPhone(quote?.createdByUser?.phone)
                                : ''}
                        </div>
                    </div>
                </Col>
                <Col md={12} className="user-info-col">
                    <div className="user-info text-right">
                        <div className="info-header">{t('quotes.heading.for')}</div>
                        <div className="name">{quote?.contactName}</div>
                        <div className="no-space">{quote?.contactEmail}</div>
                        <div className="no-space">
                            {quote?.contactPhone ? maskPhone(quote?.contactPhone) : ''}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    </>
);

const ItemList = ({
    quote,
    totalPrice,
    isReseller,
    totalEarning,
    t,
}: {
    quote: any;
    totalPrice: () => number;
    isReseller: boolean;
    totalEarning: () => number;
    t: TFunction<['quote', 'common']>;
}) => {
    const items = quote?.items;
    const hasModule = items?.some((item: IQuote.QuoteItem) => item.serviceId === null);
    return (
        <div className="details-section quote-items-table">
            <div className="section-header">{t('quotes.heading.itemDetails')}</div>
            <Row className="item-list">
                <Col span={24}>
                    <Row className="table-header">
                        <Col span={4}>{t('quotes.fields.item.label')}</Col>
                        <Col span={3}>{t('quotes.fields.unitPrice.label')}</Col>
                        <Col span={3}>{t('quotes.fields.qty.label')}</Col>
                        <Col span={3}>{t('quotes.fields.taxAmount.label')}</Col>
                        <Col span={4}>{t('quotes.fields.frequency.label')}</Col>
                        {hasModule && <Col span={4}>{t('quotes.fields.tenant.label')}</Col>}
                        <Col span={3}>{t('quotes.fields.total.label')}</Col>
                    </Row>
                    {quote?.items?.map((item: IQuote.QuoteItem) => {
                        const isItemModule = item.serviceId === null;
                        return (
                            <Row key={item.id} className="list-product-item">
                                <Col span={4}>{item.productName}</Col>
                                <Col span={3}>{formatPrice(item.unitPrice)}</Col>
                                <Col span={3}>{item.qty}</Col>
                                <Col span={3}>
                                    {!isItemModule ? `${item.taxPercentage}%` : '--'}
                                </Col>
                                <Col span={4}>
                                    {t(`services.frequency.${item.frequency}`, { ns: 'common' })}
                                </Col>
                                {hasModule && (
                                    <Col
                                        title={item.tenantName}
                                        className={`truncate ${!isItemModule && '!pl-9'}`}
                                        span={4}
                                    >
                                        {isItemModule ? item.tenantName : '--'}
                                    </Col>
                                )}
                                <Col span={3}>{formatPrice(item.total)}</Col>
                            </Row>
                        );
                    })}
                </Col>
            </Row>
            <div className="sum-total flex justify-end">
                <span className="flex">{t('quotes.heading.total')}:</span>
                <span className="ml-2">{formatPrice(totalPrice())}</span>
            </div>
            {isReseller && (
                <div className="sum-total flex justify-end">
                    <span>{t('crm_internal.quote.total_commission', { ns: 'common' })}:</span>
                    <span className="ml-2">{formatPrice(totalEarning())}</span>
                </div>
            )}
        </div>
    );
};

const PaymentPlans = ({
    quote,
    planTotalPrice,
    planEarning,
    payInvoiceWithPlan,
    isAuthenticated,
    userIdentity,
    getPlanItemInformation,
    isReseller,
    t,
}: {
    quote: IQuote.QuoteDetails | undefined;
    planTotalPrice: (plan: IQuote.IPlan) => number;
    planEarning: (plan: IQuote.IPlan) => number;
    payInvoiceWithPlan: (invoiceId: string) => void;
    isAuthenticated: boolean;
    userIdentity: IUser.IUserDto | undefined;
    getPlanItemInformation: (planItem: IQuote.IPaymentPlanItem) => IQuote.IPaymentPlanItem;
    isReseller: boolean;
    t: TFunction<['quote', 'common'], undefined>;
}) => (
    <>
        {quote?.paymentPlans?.length ? (
            <div className="details-section">
                <div className="section-header payment-plans-title mt-4 mb-0">
                    {t('quotes.heading.plans')}
                </div>
                {quote?.paymentPlans.map((plan, idx) => (
                    <div key={plan?.id} className="mt-8 payment-plan-detail py-6 px-4">
                        <div className="plan-index">{`#${
                            idx + 1 < 10 ? `0${idx + 1}` : idx + 1
                        }`}</div>
                        <div className="date-container mb-4 flex justify-between">
                            <span className="date-title">
                                {t('quotes.heading.payment_date')} -{' '}
                                {formatDate(plan?.paymentDate, 'MMM DD, YYYY')}
                            </span>
                            <span className="date-title">
                                {t('quotes.heading.payment_due_date')} -{' '}
                                {formatDate(plan?.paymentDueDate, 'MMM DD, YYYY')}
                            </span>
                        </div>
                        <Row className="item-list">
                            <Col span={24}>
                                <Row className="table-header">
                                    <Col span={8}>{t('quotes.fields.item.label')}</Col>
                                    <Col span={6}>{t('quotes.fields.frequency.label')}</Col>
                                    <Col span={6}>{t('quotes.fields.percentage.label')}</Col>
                                    <Col span={4}>{t('quotes.fields.total.label')}</Col>
                                </Row>
                                {plan?.paymentPlanItems.map((planItem) => {
                                    const { frequency, quoteItemName } =
                                        getPlanItemInformation(planItem);
                                    return (
                                        <Row key={planItem.id} className="list-product-item">
                                            <Col span={8}>{quoteItemName}</Col>
                                            <Col span={6}>{t('quotes.frequency.' + frequency)}</Col>
                                            <Col span={6}>{planItem.percentage}</Col>
                                            <Col span={4}>{formatPrice(planItem.total)}</Col>
                                        </Row>
                                    );
                                })}
                            </Col>
                        </Row>
                        <div className="sum-total flex">
                            <span>{t('quotes.heading.totalPlanPrice')}</span>
                            <span className="ml-auto">{formatPrice(planTotalPrice(plan))}</span>
                        </div>
                        {isReseller && (
                            <div className="sum-total flex mt-2">
                                <span>
                                    {t('crm_internal.quote.total_earning', { ns: 'common' })}
                                </span>
                                <span className="ml-auto">{formatPrice(planEarning(plan))}</span>
                            </div>
                        )}
                        <div className="mt-6 flex justify-end">
                            {(quote?.status === QuoteStatus.Approved ||
                                quote?.status === QuoteStatus.Paid) &&
                                quote?.paymentPlans?.length &&
                                (!isAuthenticated || userIdentity?.id === quote?.contactUserId) && (
                                    <Button
                                        onClick={() => payInvoiceWithPlan(plan.invoiceId)}
                                        type="primary"
                                        className="ml-4"
                                    >
                                        {t('quotes.actions.invoice')}
                                    </Button>
                                )}
                        </div>
                    </div>
                ))}
            </div>
        ) : null}
    </>
);

const TermsAndConditions = ({
    terms,
    t,
}: {
    terms: string | undefined;
    t: TFunction<['quote', 'common'], undefined>;
}) => (
    <div className="details-section">
        <div className="section-header">{t('quotes.fields.tc.label')}</div>
        <Row gutter={20}>
            <Col md={24}>
                <div dangerouslySetInnerHTML={{ __html: terms as string }}></div>
            </Col>
        </Row>
    </div>
);

const QuoteActions = ({
    quote,
    isAuthenticated,
    loading,
    rejectLoading,
    isSendingOtp,
    handleQuoteAction,
    pay,
    goBack,
    userIdentity,
    t,
}: {
    quote: IQuote.QuoteDetails | undefined;
    isAuthenticated: boolean;
    loading: boolean;
    rejectLoading: boolean;
    isSendingOtp: boolean;
    handleQuoteAction: (type: QuoteAction) => void;
    pay: () => void;
    goBack: () => void;
    userIdentity: IUser.IUserDto | undefined;
    t: TFunction<['quote', 'common']>;
}) => (
    <div className="details-section no-print detail-actions">
        <div className="flex flex-row-reverse">
            {quote?.status === QuoteStatus.Pending &&
                (!isAuthenticated || userIdentity?.id === quote?.contactUserId) && (
                    <>
                        <Button
                            type="primary"
                            className="ml-4"
                            disabled={rejectLoading || isSendingOtp}
                            loading={loading || isSendingOtp}
                            onClick={(e) => {
                                e.preventDefault();
                                handleQuoteAction('approve');
                            }}
                        >
                            {t('quotes.actions.approve')}{' '}
                        </Button>
                        <Popconfirm
                            onConfirm={(e) => {
                                e?.preventDefault();
                                handleQuoteAction('reject');
                            }}
                            title={t('crm_internal.quote.reject_confirm', { ns: 'common' })}
                        >
                            <Button
                                disabled={loading || isSendingOtp}
                                loading={rejectLoading || isSendingOtp}
                                className="ml-4"
                            >
                                {t('quotes.actions.reject')}
                            </Button>
                        </Popconfirm>
                    </>
                )}
            {(quote?.status === QuoteStatus.Approved || quote?.status === QuoteStatus.Paid) &&
                !quote?.paymentPlans?.length &&
                (!isAuthenticated || userIdentity?.id === quote?.contactUserId) && (
                    <Button onClick={pay} type="primary" className="ml-4">
                        {t('quotes.actions.invoice')}
                    </Button>
                )}
            {isAuthenticated && <Button onClick={goBack}>{t('quotes.actions.cancel')}</Button>}
        </div>
    </div>
);
