import './styles.scss';

import { PAGE_SIZE } from '@constants/index.constant';
import { IMarketplace } from '@interfaces/marketplace';
import { ISubscription } from '@interfaces/subscription';
import { useTable } from '@refinedev/antd';
import { useNavigation, useTranslate } from '@refinedev/core';
import { convertPrice, formatDate } from '@utils/resource';
import { Button, DatePicker, Table } from 'antd';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { formatAppSubscriptionPeriodEnd, RECURRING_SUBSCRIPTION_SUBTRACT_DAY } from 'utils/date';
const { RangePicker } = DatePicker;

export const BillingAppSubscriptionCrmIndex: React.FC = () => {
    const translate = useTranslate();
    const { id } = useParams();
    const { goBack } = useNavigation();

    const { tableProps, setFilters } = useTable<ISubscription.SubscriptionList>({
        resource: `v1/application-subscriptions/${id}`,
        sorters: {
            initial: [
                {
                    field: 'createdAt',
                    order: 'desc',
                },
            ],
        },
        pagination: {
            pageSize: PAGE_SIZE,
            current: 1,
        },
    });

    const ranges: any = {
        [translate('reports.rangeDate.yesterday')]: [dayjs().add(-1, 'd'), dayjs().add(-1, 'd')],

        [translate('reports.rangeDate.today')]: [dayjs(), dayjs()],

        [translate('reports.rangeDate.last7Days')]: [dayjs().add(-7, 'd'), dayjs()],

        [translate('reports.rangeDate.last14Days')]: [dayjs().add(-14, 'd'), dayjs()],

        [translate('reports.rangeDate.last30Days')]: [dayjs().add(-30, 'd'), dayjs()],

        [translate('reports.rangeDate.last90Days')]: [dayjs().add(-90, 'd'), dayjs()],

        [translate('reports.rangeDate.thisWeek')]: [dayjs().startOf('week'), dayjs()],

        [translate('reports.rangeDate.thisMonth')]: [dayjs().startOf('month'), dayjs()],

        [translate('reports.rangeDate.thisYear')]: [dayjs().startOf('year'), dayjs()],
    };
    const onChangeDateRange = (dayString: string[]) => {
        setFilters((prev) => {
            return [
                {
                    field: 'dateStart',
                    operator: 'eq',
                    value: dayString[0],
                },
                {
                    field: 'dateEnd',
                    operator: 'eq',
                    value: dayString[1],
                },
            ];
        });
    };

    return (
        <>
            <section className="item-list-container application-subscription-page-wrapper">
                <div className="overflow-hidden">
                    <div className="list-header">
                        <div className="filter-wrapper">
                            <div className="filter-range-date relative " id="rangeDateArea">
                                <RangePicker
                                    size="large"
                                    ranges={ranges}
                                    disabledDate={(date) => +date > +dayjs()}
                                    onChange={(e: any, dayString: string[]) =>
                                        onChangeDateRange(dayString)
                                    }
                                    getPopupContainer={() =>
                                        document.getElementById('rangeDateArea') as HTMLElement
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="list-content table-wrapper">
                        <Table {...tableProps} rowKey="id">
                            <Table.Column
                                sorter
                                title={<>{translate('subscriptions.createdAt')}</>}
                                dataIndex="createdAt"
                                key="createdAt"
                                render={(text, _) => {
                                    return <p className="table-tbody-text">{formatDate(text)}</p>;
                                }}
                            />
                            <Table.Column
                                sorter
                                title={<>{translate('subscriptions.periodStart')}</>}
                                dataIndex="periodStart"
                                key="periodStart"
                                render={(text, _) => {
                                    return <p className="table-tbody-text">{formatDate(text)}</p>;
                                }}
                            />
                            <Table.Column
                                sorter
                                title={<>{translate('subscriptions.periodEnd')}</>}
                                dataIndex="periodEnd"
                                key="periodEnd"
                                render={(periodEnd, record: IMarketplace.ISubscriptionInvoice) => {
                                    return (
                                        <p className="table-tbody-text">
                                            {formatAppSubscriptionPeriodEnd(
                                                RECURRING_SUBSCRIPTION_SUBTRACT_DAY,
                                                periodEnd as Date,
                                                record,
                                            )}
                                        </p>
                                    );
                                }}
                            />
                            <Table.Column
                                sorter
                                title={<>{translate('subscriptions.amount')}</>}
                                dataIndex="amount"
                                key="amount"
                                render={(text, _) => {
                                    return <p className="table-tbody-text">{convertPrice(text)}</p>;
                                }}
                            />
                            <Table.Column
                                title={<>{translate('subscriptions.statusTitle')}</>}
                                dataIndex="status"
                                key="status"
                                render={(status, _) => {
                                    return (
                                        <p className="table-tbody-text">
                                            {translate(
                                                'subscriptions.applicationSubscriptionInvoiceStatus.' +
                                                    status.toString(),
                                            )}
                                        </p>
                                    );
                                }}
                            />
                        </Table>
                    </div>
                    <div className="go-page-list flex justify-end pt-6">
                        <Button onClick={goBack}>{translate('buttons.cancel')}</Button>
                    </div>
                </div>
            </section>
        </>
    );
};
