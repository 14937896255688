import './styles.scss';

import { Button, FormInstance, Modal, ModalProps } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectPartnerCrm } from '../../components/internal-crm/select-partner';
import { PartnerTypeEnum } from '../../enums/partner-type.enum';
import { IUser } from '../../interfaces/user';
interface ResellerModalProps {
    isResellerUpdating: boolean;
    closeResellerModal: () => void;
    handleResellerModalCancel: () => void;
    handleDeleteReseller: (invoiceId: string) => void;
    handleAddReseller: (invoiceId: string, resellerId: string | null) => void;
    resellerSelected: string | undefined;
    setResellerSelected: (resellerId: string | undefined) => void;
    invoiceSelected: string | undefined;
    resellerModalProps: ModalProps;
    currentReseller: IUser.IUserDto | undefined;
    partnerForm?: FormInstance;
}
const ResellerModal: React.FC<ResellerModalProps> = ({
    closeResellerModal,
    handleAddReseller,
    handleResellerModalCancel,
    handleDeleteReseller,
    invoiceSelected,
    isResellerUpdating,
    resellerSelected,
    setResellerSelected,
    resellerModalProps,
    currentReseller,
    partnerForm,
}) => {
    const [resetFilters, setResetFilters] = useState(false);
    const { t: translate } = useTranslation(['common', 'contact']);
    const resetModal = () => {
        setResetFilters(true);
        setResellerSelected(undefined);
        closeResellerModal();
    };
    return (
        <Modal
            {...resellerModalProps}
            title={translate('subscriptions.reseller', {
                ns: 'common',
            })}
            maskClosable={false}
            width={650}
            wrapClassName="payment-modal"
            onOk={() => {
                if (resellerSelected && invoiceSelected) {
                    handleAddReseller(invoiceSelected, resellerSelected);
                    resetModal();
                }
            }}
            footer={[
                <div className="flex justify-end gap-2">
                    <Button key="back" onClick={handleResellerModalCancel}>
                        {translate('common.cancel')}
                    </Button>
                    <Button
                        className="delete-button"
                        disabled={!currentReseller}
                        onClick={() => {
                            if (invoiceSelected) {
                                handleDeleteReseller(invoiceSelected);
                                resetModal();
                            }
                        }}
                    >
                        {translate('actions.delete')}
                    </Button>
                    <Button
                        key="submit"
                        type="primary"
                        loading={isResellerUpdating}
                        onClick={() => {
                            partnerForm?.validateFields(['partner']);
                            if (resellerSelected && invoiceSelected) {
                                handleAddReseller(invoiceSelected, resellerSelected);
                                resetModal();
                            }
                        }}
                    >
                        {translate('common.submit')}
                    </Button>
                </div>,
            ]}
            className="reseller-modal"
        >
            <SelectPartnerCrm
                isActivePartner
                hasMerchant
                type={PartnerTypeEnum.RESELLER}
                hideDeactivated
                key="1"
                currentPartner={currentReseller}
                onChange={(option, _) => {
                    setResellerSelected(option);
                }}
                allowClear
                placeholder={translate('contacts.fields.search.placeholder_reseller', {
                    ns: 'contact',
                })}
                getSelectedPartner={(partner) => {
                    if (partner) {
                        setResellerSelected(partner.id ?? '');
                    }
                }}
                className="search-item custom-width"
                clearPartner={() => setResellerSelected(undefined)}
                rules={[
                    {
                        required: true,
                        message: translate('subscriptions.messages.reseller_required'),
                    },
                ]}
                form={partnerForm}
            />
        </Modal>
    );
};

export default ResellerModal;
