import './styles.scss';

import { AntDesignOutlined, PlusOutlined } from '@ant-design/icons';
import { PartnerCrmDetail } from '@components/internal-crm/partner/detail';
import { LoginIcon } from '@components/layout/icons/login';
import { Chip } from '@components/modules/chip';
import { CustomSearch } from '@components/modules/custom-search';
import { CustomPagination } from '@components/modules/pagination';
import { PINConfirm } from '@components/modules/pin-confirm';
import { PAGE_SIZE } from '@constants/index.constant';
import { PERMISSIONS } from '@constants/permission';
import { PartnerTypeEnum } from '@enums/partner-type.enum';
import { TenantStatusEnum } from '@enums/tenant-status.enum';
import { IPartner } from '@interfaces/partner';
import { ITenant } from '@interfaces/tenant';
import {
    DeleteButton,
    EditButton,
    getDefaultSortOrder,
    mapAntdSorterToCrudSorting,
    useDrawerForm,
} from '@refinedev/antd';
import {
    LogicalFilter,
    useApiUrl,
    useCustomMutation,
    usePermissions,
    useTable,
} from '@refinedev/core';
import { formatDate, getPublicMediaUrl } from '@utils/resource';
import type { MenuProps } from 'antd';
import {
    Avatar,
    Button,
    Dropdown,
    Menu,
    notification,
    Popconfirm,
    Table,
    TablePaginationConfig,
} from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { DataProviderNameEnum } from 'dataProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

enum TableActionEnum {
    INVITE = 'invite',
    EDIT = 'edit',
    DELETE = 'delete',
    LOGIN_ON_BEHALF = 'login_on_behalf',
}

export const PartnerCrmIndex: React.FC = () => {
    const { t } = useTranslation(['partner', 'common']);
    const { mutate } = useCustomMutation();
    const apiUrl = useApiUrl(DataProviderNameEnum.INTERNAL_CRM);
    const dataProviderName = DataProviderNameEnum.INTERNAL_CRM;

    const [isLoginOnBehalf, setIsLoginOnBehalf] = useState(false);
    const [loginOnBehalfId, setLoginOnBehalfId] = useState<{ userId: string; tenantId: string }>({
        userId: '',
        tenantId: '',
    });
    const { data: userRights } = usePermissions<string[]>();
    const partnerType = PartnerTypeEnum.RESELLER;
    // Get list partner
    const {
        setFilters,
        setSorters,
        sorters,
        filters,
        tableQueryResult,
        setCurrent,
        current,
        setPageSize,
        pageSize,
    } = useTable<ITenant.ITenantInfor>({
        resource: 'v1/partner',
        sorters: {
            initial: [
                {
                    field: 'name',
                    order: 'asc',
                },
            ],
        },
        filters: {
            initial: [
                {
                    field: 'type',
                    operator: 'eq',
                    value: partnerType,
                },
            ],
        },
        pagination: {
            pageSize: PAGE_SIZE,
            current: 1,
        },
        initialPageSize: PAGE_SIZE,
        syncWithLocation: true,
        dataProviderName: DataProviderNameEnum.INTERNAL_CRM,
    });

    // Create partner
    const {
        drawerProps: createDrawerProps,
        formProps: createFormProps,
        saveButtonProps: createSaveButtonProps,
        show: createShow,
    } = useDrawerForm<IPartner.IPartnerInfo>({
        dataProviderName,
        action: 'create',
        resource: 'v1/partner',
        successNotification: { message: 'Successfully created', type: 'success' },
        redirect: false,
        onMutationSuccess: () => {
            createFormProps.form.resetFields();
        },
    });

    // Edit partner
    const {
        drawerProps: editDrawerProps,
        formProps: editFormProps,
        saveButtonProps: editSaveButtonProps,
        show: editShow,
    } = useDrawerForm<IPartner.IPartnerInfo>({
        dataProviderName,
        action: 'edit',
        resource: `v1/partner/${partnerType}`,
        successNotification: { message: 'Successfully edited', type: 'success' },
        redirect: false,
        onMutationSuccess: () => {
            tableQueryResult.refetch();
        },
    });

    const onClickItem = (key: string, record: ITenant.ITenantInfor & { tenantId?: string }) => {
        switch (key) {
            case TableActionEnum.EDIT:
                editShow(record.id);
                break;
            case TableActionEnum.LOGIN_ON_BEHALF:
                setLoginOnBehalfId({
                    tenantId: record?.tenantId ?? '',
                    userId: record?.id ?? '',
                });
                setIsLoginOnBehalf(true);
                break;
            default:
                break;
        }
    };

    const items = (record: ITenant.ITenantInfor & { tenantId?: string }) => {
        const items: MenuProps['items'] = [];
        // Send invite
        if (
            record.status === TenantStatusEnum.PENDING &&
            (userRights || []).includes(PERMISSIONS.WL_PARTNER_EDIT)
        ) {
            const sendInvite = {
                key: TableActionEnum.INVITE,
                label: (
                    <Popconfirm
                        title={t('partners.messages.resend_invitation')}
                        placement="rightBottom"
                        onConfirm={() => {
                            handleResendInvitation(record.id);
                        }}
                        okText={t('partners.yes')}
                        cancelText={t('partners.no')}
                    >
                        <Button>{t('partners.invite')}</Button>
                    </Popconfirm>
                ),
                icon: (
                    <img
                        src="/images/icons/send.svg"
                        alt="invite"
                        className="cursor-pointer mr-2"
                        width={18}
                        height={18}
                    />
                ),
            };
            items.push(sendInvite);
        }

        // Edit invite
        if ((userRights || []).includes(PERMISSIONS.WL_PARTNER_EDIT)) {
            const editInvite = {
                key: TableActionEnum.EDIT,
                label: (
                    <EditButton
                        recordItemId={record.id}
                        icon={
                            <img
                                src="/images/icons/edit.svg"
                                alt="edit"
                                className="cursor-pointer"
                                width={18}
                                height={18}
                            />
                        }
                        className="mr-5 p-0 border-0 "
                    />
                ),
            };
            items.push(editInvite);
        }

        // delete partner
        if ((userRights || []).includes(PERMISSIONS.WL_PARTNER_DELETE)) {
            const deletePartner = {
                key: TableActionEnum.DELETE,
                label: (
                    <DeleteButton
                        successNotification={{
                            message: t('delete_success', { ns: 'common' }),
                            type: 'success',
                        }}
                        errorNotification={{
                            message: t('delete_error', { ns: 'common' }),
                            type: 'error',
                        }}
                        resource="v1/partner/user"
                        recordItemId={record.id}
                        dataProviderName={dataProviderName}
                        onSuccess={() => {
                            tableQueryResult.refetch();
                        }}
                    />
                ),
            };
            items.push(deletePartner);
        }

        // Login on behalf
        if (
            record.status === TenantStatusEnum.ACTIVE &&
            (userRights || []).includes(PERMISSIONS.WL_PARTNER_LIST)
        ) {
            const loginOnBehalf = {
                key: TableActionEnum.LOGIN_ON_BEHALF,
                label: t('actions.login_on_behalf', { ns: 'common' }),
                icon: (
                    <div className="cursor-pointer">
                        <LoginIcon />
                    </div>
                ),
            };
            items.push(loginOnBehalf);
        }

        return items;
    };

    const renderStatus = (code: TenantStatusEnum) => {
        let label = '';
        let type = '';
        switch (code) {
            case TenantStatusEnum.PENDING:
                label = t('partners.status.pending', { ns: 'partner' });
                type = 'warning';
                break;
            case TenantStatusEnum.ACTIVE:
                label = t('partners.status.active', { ns: 'partner' });
                type = 'success';
                break;
            case TenantStatusEnum.DEACTIVATED:
                label = t('partners.status.deactivated', { ns: 'partner' });
                type = 'disable';
                break;
        }
        return <Chip {...{ label, type }} />;
    };

    const handleResendInvitation = (id: string) => {
        const url = `${apiUrl}/v1/partner/invitation/${partnerType}/${id}`;
        mutate(
            {
                url: url,
                method: 'put',
                values: {},
            },
            {
                onError: (error, variables, context) => {
                    // An error happened!
                    console.error(error);
                },
                onSuccess: (data, variables, context) => {
                    if (data && data.data?.isSuccess) {
                        notification.success({
                            className: 'success-notification',
                            message: '',
                            description: t('partners.messages.send_invitation_success'),
                        });
                    } else {
                        notification.error({
                            className: 'error-notification',
                            message: '',
                            description: t('partners.errors.send_invitation_failed'),
                        });
                    }
                },
            },
        );
    };

    const onChangeTable = (
        _: TablePaginationConfig,
        __: Record<string, (string | number | boolean) | (string | number | boolean)[] | null>,
        sorter: SorterResult<ITenant.ITenantInfor> | SorterResult<ITenant.ITenantInfor>[],
    ) => {
        if (sorter && Object.keys(sorter).length > 0) {
            // Map Antd:Sorter -> refine:CrudSorting
            const crudSorting = mapAntdSorterToCrudSorting(sorter);
            setSorters(crudSorting);
        }
    };

    const handleVerifyPinSuccessfully = (data: ITenant.ILoginOnBehalf) => {
        if (data) {
            window.open(
                `${window.location.protocol}//${data.tenantDomain}/login-on-behalf?token=${data.loginPayload?.token}`,
                '_self',
            );
        }
    };

    return (
        <>
            <PartnerCrmDetail
                drawerProps={createDrawerProps}
                formProps={createFormProps}
                saveButtonProps={createSaveButtonProps}
                isEditMode={false}
                type={partnerType}
            />
            <PartnerCrmDetail
                drawerProps={editDrawerProps}
                formProps={editFormProps}
                saveButtonProps={editSaveButtonProps}
                isEditMode={true}
                type={partnerType}
            />
            <section className="partner-list-container">
                <div className="list-header">
                    <CustomSearch
                        placeholder={t('partners.fields.type.placeholder', { ns: 'partner' })}
                        className={'search-item'}
                        defaultValue={
                            filters?.find((f) => (f as LogicalFilter).field === 'filter')?.value
                        }
                        onChange={(event) => {
                            setFilters([
                                {
                                    field: 'filter',
                                    operator: 'eq',
                                    value: event.target.value,
                                },
                            ]);
                            setCurrent(1);
                        }}
                        allowClear={true}
                    />
                    {(userRights || []).includes(PERMISSIONS.WL_PARTNER_CREATE) ? (
                        <Button className="btn-add-new" type="primary" onClick={() => createShow()}>
                            <PlusOutlined />
                            {t('add_new', { ns: 'common' })}
                        </Button>
                    ) : null}
                </div>

                <div className="overflow-hidden">
                    <div className="list-content table-wrapper">
                        <Table
                            dataSource={tableQueryResult.data?.data}
                            loading={tableQueryResult.isFetching}
                            onChange={onChangeTable}
                            rowKey="id"
                            pagination={false}
                            tableLayout="fixed"
                            scroll={{ x: '900px' }}
                        >
                            <Table.Column
                                title={<>{t('partners.name')}</>}
                                dataIndex="name"
                                key="name"
                                sorter
                                width={250}
                                defaultSortOrder={getDefaultSortOrder('name', sorters)}
                                render={(row, record: ITenant.ITenantInfor) => (
                                    <div className="flex justify-between items-center ">
                                        <div className="flex justify-start items-center">
                                            <Avatar
                                                size={40}
                                                src={
                                                    record.avatarUrl
                                                        ? getPublicMediaUrl(record.avatarUrl)
                                                        : null
                                                }
                                                icon={<AntDesignOutlined />}
                                            />
                                            <p className="table-tbody-text ml-2 item-name pd-0 w-52">
                                                {row}
                                            </p>
                                        </div>
                                    </div>
                                )}
                            />
                            <Table.Column
                                sorter
                                defaultSortOrder={getDefaultSortOrder('email', sorters)}
                                title={<>{t('partners.fields.email.label', { ns: 'partner' })}</>}
                                dataIndex="email"
                                key="email"
                                width={250}
                                render={(text, _) => <p className="table-tbody-text">{text}</p>}
                            />
                            <Table.Column
                                sorter
                                defaultSortOrder={getDefaultSortOrder('status', sorters)}
                                title={<>{t('partners.fields.status.label', { ns: 'partner' })}</>}
                                dataIndex="status"
                                key="status"
                                render={(text, _) => renderStatus(text)}
                            />

                            <Table.Column
                                sorter
                                defaultSortOrder={getDefaultSortOrder('createdAt', sorters)}
                                title={<>{t('partners.created_at', { ns: 'partner' })}</>}
                                dataIndex="createdAt"
                                key="createdAt"
                                render={(text, _) => (
                                    <p className="table-tbody-text">{formatDate(text)}</p>
                                )}
                            />
                            <Table.Column
                                dataIndex="id"
                                key="action"
                                width={100}
                                fixed="right"
                                render={(_, record: ITenant.ITenantInfor) => {
                                    return (
                                        <div className="flex justify-end items-center">
                                            {(userRights || []).some((_right) =>
                                                [
                                                    PERMISSIONS.WL_PARTNER_EDIT,
                                                    PERMISSIONS.WL_PARTNER_DELETE,
                                                ].includes(_right),
                                            ) ? (
                                                <Dropdown
                                                    menu={{
                                                        items: items(record),
                                                        onClick: (e) => onClickItem(e.key, record),
                                                    }}
                                                    placement="bottomRight"
                                                    arrow
                                                    overlayClassName="contact-dropdown-container"
                                                    trigger={['click']}
                                                >
                                                    <img
                                                        src="/images/icons/dots-vertical.svg"
                                                        alt="more"
                                                        className="cursor-pointer"
                                                    />
                                                </Dropdown>
                                            ) : null}
                                        </div>
                                    );
                                }}
                            />
                        </Table>
                    </div>
                    <div className="pagination-container pt-3 pb-4 px-6">
                        <CustomPagination
                            pageSize={pageSize}
                            total={tableQueryResult?.data?.total}
                            current={current}
                            onChange={(currentPage: number, size: number) => {
                                setCurrent(currentPage);
                                setPageSize(size);
                            }}
                        />
                    </div>
                </div>

                {isLoginOnBehalf ? (
                    <PINConfirm
                        title={t('pin.title', { ns: 'common' })}
                        visible={isLoginOnBehalf}
                        confirmData={{
                            tenantId: loginOnBehalfId?.tenantId ?? '',
                            userId: loginOnBehalfId?.userId ?? '',
                        }}
                        setVisible={setIsLoginOnBehalf}
                        onClose={(data: ITenant.ILoginOnBehalf | null) => {
                            if (!data) {
                                return;
                            }
                            handleVerifyPinSuccessfully(data);
                        }}
                        dataProviderName={DataProviderNameEnum.INTERNAL_CRM}
                    />
                ) : null}
            </section>
        </>
    );
};
