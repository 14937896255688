import { Create } from '@refinedev/antd';
import { useTranslate } from '@refinedev/core';
import { ButtonProps, DrawerProps, FormProps, Grid, Modal, ModalProps } from 'antd';

import { TenantDetailFormCrmIndex } from './detail-form';

type TenantDetailsProps = {
    modalProps: ModalProps;
    formProps: FormProps;
    saveButtonProps: ButtonProps;
    invoiceId: string;
    disabledSkipOnboarding?: boolean;
};

export const TenantDetailCrmIndex: React.FC<TenantDetailsProps> = ({
    modalProps,
    formProps,
    saveButtonProps,
    invoiceId,
    disabledSkipOnboarding,
}) => {
    const t = useTranslate();
    const breakpoint = Grid.useBreakpoint();

    return (
        <>
            <Modal
                {...modalProps}
                maskClosable={false}
                footer={null}
                width={800}
                wrapClassName="tenant-detail"
            >
                <Create
                    breadcrumb={null}
                    headerProps={{ extra: null, backIcon: null }}
                    saveButtonProps={saveButtonProps}
                >
                    <TenantDetailFormCrmIndex
                        formProps={formProps}
                        mode={'add'}
                        invoiceId={invoiceId}
                        disabledSkipOnboarding={disabledSkipOnboarding}
                    />
                </Create>
            </Modal>
        </>
    );
};
