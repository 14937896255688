import { SelectContact } from '@components/modules/select-contact';
import { DATE_FORMAT } from '@constants/index.constant';
import { Col, DatePicker, Form, Row } from 'antd';
import { DataProviderNameEnum } from 'dataProvider';
import dayjs from 'dayjs';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IContact } from '../../../interfaces/contact';

type BillInformationProps = {
    form: any;
    onChangeContact?: (val: any) => void;
    mode?: 'create' | 'edit';
};
export const BillInformation: FC<BillInformationProps> = (props) => {
    const { form, onChangeContact, mode = 'create' } = props;
    const { t } = useTranslation(['quote', 'common']);

    const dataProviderName = DataProviderNameEnum.INTERNAL_CRM;

    const disablePaymentDate = (current: dayjs.Dayjs): boolean => {
        return current < dayjs().startOf('day');
    };

    const disableDueDate = (current: dayjs.Dayjs): boolean => {
        const paymentDate = form.getFieldValue('date');
        return current < (paymentDate ? dayjs(paymentDate).startOf('day') : dayjs().startOf('day'));
    };

    const contactId = form.getFieldValue('contactId');
    return (
        <Row gutter={20}>
            <Col xs={24} md={8} lg={8}>
                <Form.Item
                    shouldUpdate
                    label={t('quotes.fields.contact.label')}
                    name="contactId"
                    rules={[
                        {
                            required: true,
                            message: t('quotes.fields.contact.required'),
                        },
                    ]}
                >
                    <SelectContact
                        disabled={['edit'].includes(mode)}
                        contactId={contactId}
                        onChange={(val) => onChangeContact?.(val)}
                        dataProviderName={dataProviderName}
                    />
                </Form.Item>
            </Col>
            <Col md={0} lg={2}></Col>
            <Col xs={24} md={8} lg={7}>
                <Form.Item
                    label={t('quotes.fields.paymentDate.label')}
                    name="date"
                    rules={[
                        {
                            required: true,
                            message: t('quotes.fields.paymentDate.required'),
                        },
                    ]}
                >
                    <DatePicker
                        getPopupContainer={(trigger) => trigger?.parentNode as HTMLElement}
                        format={DATE_FORMAT}
                        disabledDate={(current) => disablePaymentDate(current)}
                        onChange={() => form.resetFields(['dueDate'])}
                    />
                </Form.Item>
            </Col>
            <Col xs={24} md={8} lg={7}>
                <Form.Item
                    label={t('quotes.fields.paymentDueDate.label')}
                    name="dueDate"
                    rules={[
                        {
                            required: true,
                            message: t('quotes.fields.paymentDueDate.required'),
                        },
                    ]}
                >
                    <DatePicker
                        getPopupContainer={(trigger) => trigger?.parentNode as HTMLElement}
                        format={DATE_FORMAT}
                        disabledDate={(current) => disableDueDate(current)}
                    />
                </Form.Item>
            </Col>
        </Row>
    );
};
