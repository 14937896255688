import './style.scss';

import { ServeralFileUpload } from '@components/modules/serveral-file-upload';
import { ServeralImageUpload } from '@components/modules/serveral-image-upload';
import { IHelpdesk } from '@interfaces/helpdesk';
import { getPublicMediaUrl } from '@utils/resource';
import { Col, Image, Input, Row, UploadFile } from 'antd';
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';
import { useEffect, useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { useTranslation } from 'react-i18next';

interface IFile {
    id: string;
    url: string;
    name: string;
}

interface IFooterHelpdeskProps {
    handleSendMessage: (message: IHelpdesk.ICreateMessageRequest) => boolean;
    ticketId?: string;
}

export const FooterTicketMessageDetailComponent: React.FC<IFooterHelpdeskProps> = ({
    handleSendMessage,
    ticketId,
}) => {
    const { t } = useTranslation(['conversation', 'common']);
    const [messageLive, setMessageLive] = useState<string | null>(null);
    const [visibleEmoji, setVisibleEmoji] = useState<boolean>(false);
    const [triggerEmoji, setTriggerEmoji] = useState<boolean>(false);
    const [images, setImages] = useState<Array<IFile>>([]);
    const [files, setFiles] = useState<Array<IFile>>([]);
    const onEmojiClick = (emojiData: EmojiClickData) => {
        if (!messageLive) {
            setMessageLive(emojiData.emoji);
        } else {
            setMessageLive(`${messageLive || ''} ${emojiData.emoji}`);
        }
    };

    useEffect(() => {
        if (ticketId) {
            handleClearMessage();
        }
    }, [ticketId]);

    const handleClearMessage = () => {
        setFiles([]);
        setImages([]);
        setMessageLive(null);
    };

    const handleEmojiClick = () => {
        setVisibleEmoji(!visibleEmoji);
        setTriggerEmoji(false);
    };

    const closeEmojiContainer = () => {
        setTriggerEmoji(true);
        if (visibleEmoji && triggerEmoji) {
            setVisibleEmoji(false);
        }
    };

    const ref = useDetectClickOutside({ onTriggered: closeEmojiContainer });

    // Add image to message
    const onAddImage = (file: UploadFile) => {
        const newList = [...images];
        newList.push({
            id: file.uid,
            url: file.response,
            name: file.name,
        });
        setImages(newList);
    };

    const onAddFile = (file: UploadFile) => {
        const newList = [...files];
        newList.push({
            id: file.uid,
            url: file.response,
            name: file.name,
        });
        setFiles(newList);
    };
    const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.keyCode === 13 && !e.shiftKey) {
            e?.preventDefault();
        }
        if (
            e.keyCode === 13 &&
            e.key === 'Enter' &&
            !e.shiftKey &&
            (messageLive?.trim() || images.length || files.length)
        ) {
            //handle send message
            const params: IHelpdesk.ICreateMessageRequest = {
                content: messageLive,
                images: images,
                files: files,
            };
            const result = handleSendMessage(params);
            if (result) {
                handleClearMessage();
            }
        }
    };

    const removeImage = (file: IFile) => {
        const index = images.indexOf(file);
        const newFileList = images.slice();
        newFileList.splice(index, 1);
        setImages(newFileList);
    };

    const removeFile = (file: IFile) => {
        const index = files.indexOf(file);
        const newFileList = files.slice();
        newFileList.splice(index, 1);
        setFiles(newFileList);
    };

    return (
        <>
            <Row className="converastion-detail-footer w-full">
                {images.length ? (
                    <div className="file-container flex">
                        {images.map((item) => (
                            <div key={item.id} className="image-box flex relative p-3">
                                <Image
                                    width={60}
                                    height={60}
                                    className="flex justify-center"
                                    src={getPublicMediaUrl(item.url)}
                                    preview={false}
                                />

                                <div onClick={() => removeImage(item)}>
                                    <Image
                                        src={'/images/icons/close-icon.svg'}
                                        preview={false}
                                        className="icon-action cursor-pointer"
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                ) : null}

                {files.length ? (
                    <div className="file-container flex items-center">
                        {files.map((file) => (
                            <div key={file.id} className="file-box flex relative p-3">
                                <div className="document-box flex items-center w-fit">
                                    <Image
                                        className="customer-cell-avatar pl-3"
                                        src={'/images/icons/file-icon.svg'}
                                        preview={false}
                                    />
                                    <div className="p-3">
                                        <span className="document-name">{file.name}</span>
                                    </div>
                                </div>
                                <div onClick={() => removeFile(file)}>
                                    <Image
                                        src={'/images/icons/close-icon.svg'}
                                        preview={false}
                                        className="icon-action cursor-pointer"
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                ) : null}

                <Col span={24} className="chat-area">
                    <Input.TextArea
                        className="conversation-input"
                        placeholder={t('conversations.message')}
                        onKeyDown={(e) => onKeyDown(e)}
                        autoSize={{ minRows: 1.2, maxRows: 4 }}
                        onChange={(value) => setMessageLive(value.target.value)}
                        value={messageLive || null || undefined}
                    />
                </Col>
                <Col span={24} className="conversation-action justify-end">
                    <div className="serveral-image-upload-container">
                        <ServeralImageUpload onChange={onAddImage} />
                    </div>
                    <div className="serveral-document-upload-container">
                        <ServeralFileUpload onChange={onAddFile} />
                    </div>
                    <Image
                        className="icon-action mx-3"
                        src={'/images/icons/smile.svg'}
                        preview={false}
                        onClick={handleEmojiClick}
                    />

                    {visibleEmoji && (
                        <div className={`emoji-container`} ref={ref}>
                            <EmojiPicker
                                lazyLoadEmojis={true}
                                skinTonesDisabled={true}
                                onEmojiClick={onEmojiClick}
                            />
                        </div>
                    )}
                </Col>
            </Row>
        </>
    );
};
