import './styles.scss';

import { CreditCardOutlined, EllipsisOutlined, EyeOutlined, FormOutlined } from '@ant-design/icons';
import { PERMISSIONS } from '@constants/permission';
import { IProduct } from '@interfaces/product';
import { DeleteButton } from '@refinedev/antd';
import { BaseKey, usePermissions, useTranslate } from '@refinedev/core';
import { formatPrice, getPublicMediaUrl, isHavingPermission } from '@utils/resource';
import { Card, Divider, Dropdown, Image, Menu, Tooltip, Typography } from 'antd';
import { DataProviderNameEnum } from 'dataProvider';

const { Paragraph } = Typography;

type ProductItemProps = {
    isSysAdmin?: boolean;
    item: IProduct;
    editShow: (id?: BaseKey) => void;
};

export const ProductCrmItem: React.FC<ProductItemProps> = ({ item, editShow, isSysAdmin }) => {
    const t = useTranslate();
    const { data: rights = [] } = usePermissions<string[]>();
    const dataProviderName = DataProviderNameEnum.INTERNAL_CRM;
    const hasProductEditPermission = isHavingPermission(rights, PERMISSIONS.WL_SERVICE_EDIT);
    const hasProductDeletePermission = isHavingPermission(rights, PERMISSIONS.WL_SERVICE_DELETE);
    return (
        <Card className="product-item">
            <div className="product-item-wrapper">
                <div style={{ textAlign: 'center' }}>
                    <Image
                        preview={false}
                        src={getPublicMediaUrl(item.featureImageUrl)}
                        alt={item.name}
                    />
                </div>
                <Paragraph ellipsis={{ rows: 2, tooltip: true }} className="service-name">
                    {item.name}
                </Paragraph>
                <Tooltip placement="topLeft" title={item.description}>
                    <div className="package-content">{t('crm_internal.package_content')}</div>
                </Tooltip>
                <div className="frequency">{t('services.frequency.' + item.frequency)}</div>
                <Divider />
                <div className="actions flex justify-between">
                    <div className="money">
                        <CreditCardOutlined />
                        <span>{formatPrice(item.price)}</span>
                    </div>
                    <div className="flex items-center">
                        <div className="chip">
                            <span className={`content ${item.isActive ? '' : 'inactive'}`}>
                                <span className="dot">&nbsp;</span>
                                <span className="label">
                                    {item.isActive
                                        ? t('services.isActive')
                                        : t('services.inactive')}
                                </span>
                            </span>
                        </div>
                        <div className="service-action ml-4">
                            <Dropdown
                                overlayClassName="service-dropdown-container"
                                overlay={
                                    <Menu mode="vertical">
                                        <Menu.Item
                                            key="1"
                                            style={{
                                                fontWeight: 500,
                                            }}
                                            icon={
                                                isSysAdmin ? (
                                                    <FormOutlined
                                                        style={{
                                                            color: 'green',
                                                        }}
                                                    />
                                                ) : (
                                                    <EyeOutlined
                                                        style={{
                                                            color: 'green',
                                                        }}
                                                    />
                                                )
                                            }
                                            onClick={() => editShow(item.id)}
                                        >
                                            {hasProductEditPermission
                                                ? t('services.buttons.edit')
                                                : t('crm_internal.view')}
                                        </Menu.Item>

                                        {hasProductDeletePermission ? (
                                            <Menu.Item
                                                key="2"
                                                icon={
                                                    <DeleteButton
                                                        dataProviderName={dataProviderName}
                                                        successNotification={{
                                                            message: 'Successfully deleted',
                                                            type: 'success',
                                                        }}
                                                        resource="v1/products"
                                                        recordItemId={item.id}
                                                    />
                                                }
                                            ></Menu.Item>
                                        ) : null}
                                    </Menu>
                                }
                                trigger={['click']}
                            >
                                <EllipsisOutlined
                                    style={{
                                        fontSize: 24,
                                    }}
                                />
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    );
};
