import { DATE_FORMAT } from '@constants/index.constant';
import { IUser } from '../interfaces/user';
import { getEnvConfig } from '../getEnvConfig';
import dayjs from 'dayjs';
import { Currency, LocaleCurrency } from '@enums/currency.enum';

const FIX_CHARACTERS = 2;

export const getPublicMediaUrl = (url: string): string => {
    if (url && url.indexOf('http') === -1) {
        return `${getEnvConfig.CDN}/${url}`;
    }
    return `${url}`;
};

export const formatPrice = (price = 0, currencySymbol = '$'): string => {
    if (!price) {
        price = 0;
    }
    return price.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
};
export const formatPercentage = (price = 0): string => {
    return `${price.toFixed(FIX_CHARACTERS)}%`;
};

export const getPriceWithTax = (price: number, taxPercentage: number) => {
    return price + (price * taxPercentage) / 100;
};

export const convertPrice = (price = 0, currencySymbol = '$'): string => {
    if (!price) {
        price = 0;
    }
    return (price / 100).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    });
};

export const formatDate = (date: string | undefined, format = DATE_FORMAT): string => {
    if (!date) return '';
    return dayjs(date).format(format);
};

export const getFullNameUser = (user: IUser.IUserDto): string =>
    `${user?.firstName ? user?.firstName + ' ' : ''}${user?.lastName ?? ''}`;

export const formatPriceBaseOnCurrency = ({
    price,
    currency,
}: {
    price: number;
    currency?: string;
}): string => {
    let localeString;
    switch (currency) {
        case Currency.USD:
            localeString = LocaleCurrency.USD;
            break;

        case Currency.VND:
            localeString = LocaleCurrency.VND;
            break;
    }

    const formatter = new Intl.NumberFormat(localeString ?? 'en-US', {
        style: 'currency',
        currency: currency ?? 'USD',
    }).format(price / 100 || 0);
    const formatVND = formatter.replaceAll('.', ',');
    return currency === Currency.VND ? formatVND : formatter;
};

export const getAssetPath = (): string => {
    return `${window.location.origin}${getEnvConfig.PUBLIC_URL}/assets`;
};

export const uppercaseAll = (text: string) => {
    return text.toUpperCase();
};

export const convertData = (data: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(data, 'text/html');
    const text = doc.body.textContent || '';
    return text;
};

export const isHavingPermission = (permissions: string[], permission: string) => {
    return permissions?.indexOf(permission) !== -1;
};
