import './styles.scss';

import { GoalCrmChart } from '@components/internal-crm/charts/goal-chart';
import { LineCrmChart } from '@components/internal-crm/charts/line-chart';
import OverviewCrmChart, {
    DataOverviewProps,
} from '@components/internal-crm/charts/overview-chart';
import { DataPieChart, PieCrmChart } from '@components/internal-crm/charts/pie-chart';
import { PosSettingMenuSidebarIcon } from '@components/layout/sidebar/icons/pos/setting-menu-icon';
import { DATE_FORMAT, ROOT_TENANT } from '@constants/index.constant';
import { PERMISSIONS } from '@constants/permission';
import { UserTypeEnum } from '@enums/user-type.enum';
import { IReport } from '@interfaces/report';
import { IUser } from '@interfaces/user';
import { useTable } from '@refinedev/antd';
import {
    CrudFilters,
    HttpError,
    LogicalFilter,
    useApiUrl,
    useCreate,
    useCustom,
    useGetIdentity,
    useOne,
    usePermissions,
} from '@refinedev/core';
import { Button, Col, Form, Input, Row, Select, Spin } from 'antd';
import { DataProviderNameEnum } from 'dataProvider';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Response from '../../../data-access/responses/response';
import { isHavingPermission } from '../../../utils/resource';
import { IncomeCrmIndex } from './income';
import { OverdueInvoiceCrmIndex } from './overdue-invoice';

const REPORT_RESOURCE_URL = 'v1/report';
const GOAL_RESOURCE_URL = 'v1/goal';
const INITIAL_FILTER = [
    {
        field: 'granularity',
        operator: 'eq',
        value: 'day',
    },
    {
        field: 'dateStart',
        operator: 'eq',
        value: dayjs().startOf('month').format(DATE_FORMAT),
    },
    {
        field: 'dateEnd',
        operator: 'eq',
        value: dayjs().format(DATE_FORMAT),
    },
] as CrudFilters;

const DEFAULT_RANGE_DATE: [dayjs.Dayjs, dayjs.Dayjs] = [
    dayjs(dayjs().startOf('month'), DATE_FORMAT),
    dayjs(dayjs(), DATE_FORMAT),
];

export const RevenueCrmIndex: React.FC = () => {
    const { t: translate } = useTranslation(['common']);

    const dataProviderName = DataProviderNameEnum.INTERNAL_CRM;

    const OPTIONS_TYPE_GOAL = [
        {
            label: translate('reports.week'),
            value: 'week',
        },
        {
            label: translate('reports.month'),
            value: 'month',
        },
    ];

    const { data: useIdentity } = useGetIdentity<IUser.IUserDto>();
    const [isSettingGoal, setIsSettingGoal] = useState<boolean>(false);
    const [goalValue, setGoalValue] = useState<string>('0');
    const isReseller = useIdentity?.userType === UserTypeEnum.RESELLER;
    const { data: permissions = [] } = usePermissions<string[]>();
    const hasCreateSettingGoalPermission = isHavingPermission(
        permissions,
        PERMISSIONS.WL_REVENUE_CREATE,
    );
    const hasShowRevenuePermission =
        isHavingPermission(permissions, PERMISSIONS.WL_REVENUE_LIST) && !isReseller;

    const [dateRangeOverall, setDateRangeOverall] = useState(DEFAULT_RANGE_DATE);
    const [granularityOverall, setGranularityOverall] = useState<string>('day');

    const [dateRangeEarning, setDateRangeEarning] = useState(DEFAULT_RANGE_DATE);
    const [granularityEarning, setGranularityEarning] = useState<string>('day');

    const [dateRangeCommission, setDateRangeCommission] = useState(DEFAULT_RANGE_DATE);
    const [granularityCommission, setGranularityCommission] = useState<string>('day');

    const [dateRangeDistribution, setDateRangeDistribution] = useState(DEFAULT_RANGE_DATE);

    const { tableProps: dataEarning, setFilters: setFiltersEarning } = useTable<IReport.IRevenue>({
        dataProviderName,
        resource: `${REPORT_RESOURCE_URL}/earning`,
        initialFilter: _.cloneDeep(INITIAL_FILTER),
        hasPagination: false,
    });

    const apiUrl = useApiUrl(DataProviderNameEnum.INTERNAL_CRM);
    const isSysAdmin =
        permissions?.includes(PERMISSIONS.WL_REVENUE_LIST) &&
        useIdentity?.userType === UserTypeEnum.TENANT;

    const [formSettingGoal] = Form.useForm();
    const { mutate, isLoading: isSaveGoal } = useCreate<
        Response<IReport.IGoal | boolean>,
        HttpError,
        IReport.IGoal | boolean
    >();

    const { data: goalSetting, refetch: refetchGoalSetting } = useOne<IReport.IGoal>({
        dataProviderName,
        resource: `${GOAL_RESOURCE_URL}`,
        id: '',
    });

    const { data: toTalAmount, refetch: refetchTotalAmount } =
        useCustom<IReport.ITotalAmountInvoice>({
            dataProviderName,
            url: `${apiUrl}/${REPORT_RESOURCE_URL}/total-amount`,
            method: 'get',
            queryOptions: {
                enabled: false,
            },
            config: {
                filters: [
                    {
                        field: 'dateRange',
                        operator: 'eq',
                        value: goalSetting?.data?.goalType,
                    },
                ],
            },
        });

    const {
        tableProps: dataOverview,
        setFilters: setFiltersOverview,
        tableQueryResult: overviewTableQueryResult,
        filters: filtersOverview,
    } = useTable<DataOverviewProps>({
        dataProviderName,
        resource: `${REPORT_RESOURCE_URL}/overview`,
        initialFilter: _.cloneDeep(INITIAL_FILTER),
        hasPagination: false,
    });

    const {
        tableProps: dataDistribution,
        setFilters: setFiltersDistribution,
        tableQueryResult: distributionTableQueryResult,
        filters: filtersDistribution,
    } = useTable<DataPieChart>({
        dataProviderName,
        resource: `${REPORT_RESOURCE_URL}/distribution`,
        initialFilter: _.cloneDeep(INITIAL_FILTER),
        hasPagination: false,
    });

    const {
        tableProps: dataCommission,
        setFilters: setFiltersCommission,
        tableQueryResult: commissionTableQueryResult,
        filters: filtersCommission,
    } = useTable<IReport.IRevenue>({
        dataProviderName,
        resource: `${REPORT_RESOURCE_URL}/commission`,
        initialFilter: _.cloneDeep(INITIAL_FILTER),
        hasPagination: false,
    });

    const filterByDateRange = (prev: CrudFilters, dayString: string[]) => {
        const newFilter = prev;
        const indexDateStart = newFilter.findIndex(
            (item) => (item as LogicalFilter).field === 'dateStart',
        );
        const indexDateEnd = newFilter.findIndex(
            (item) => (item as LogicalFilter).field === 'dateEnd',
        );
        if (indexDateStart !== -1 && indexDateEnd !== -1) {
            (newFilter[indexDateStart] as LogicalFilter).value = dayString[0];
            (newFilter[indexDateEnd] as LogicalFilter).value = dayString[1];
        } else {
            newFilter.push({
                field: 'dateStart',
                operator: 'eq',
                value: dayString[0],
            });
            newFilter.push({
                field: 'dateEnd',
                operator: 'eq',
                value: dayString[1],
            });
        }
        return newFilter;
    };

    const filterByGranularity = (prev: CrudFilters, value: string) => {
        const newFilter = prev;
        const index = newFilter.findIndex(
            (item) => (item as LogicalFilter).field === 'granularity',
        );

        if (index !== -1) {
            (newFilter[index] as LogicalFilter).value = value;
        } else {
            newFilter.push({
                field: 'granularity',
                operator: 'eq',
                value: value,
            });
        }
        return newFilter;
    };

    const onChangeDateRangeEarning = (dayString: string[]) => {
        setDateRangeEarning([dayjs(dayString[0], DATE_FORMAT), dayjs(dayString[1], DATE_FORMAT)]);
        setFiltersEarning((prev) => filterByDateRange(prev, dayString));
    };

    const onChangeGranularityEarning = (value: string) => {
        setGranularityEarning(value);
        setFiltersEarning((prev) => filterByGranularity(prev, value));
    };

    const onChangeDateRangeCommission = (dayString: string[]) => {
        setDateRangeCommission([
            dayjs(dayString[0], DATE_FORMAT),
            dayjs(dayString[1], DATE_FORMAT),
        ]);
        setFiltersCommission((prev) => filterByDateRange(prev, dayString));
    };

    const onChangeGranularityCommission = (value: string) => {
        setGranularityCommission(value);
        setFiltersCommission((prev) => filterByGranularity(prev, value));
    };

    const onChangeDateRangeOverview = (dayString: string[]) => {
        setDateRangeOverall([dayjs(dayString[0], DATE_FORMAT), dayjs(dayString[1], DATE_FORMAT)]);
        setFiltersOverview((prev) => filterByDateRange(prev, dayString));
    };

    const onChangeGranularityOverview = (value: string) => {
        setGranularityOverall(value);
        setFiltersOverview((prev) => filterByGranularity(prev, value));
    };

    const onChangeDateRangeOverall = (dayString: string[]) => {
        setDateRangeDistribution([
            dayjs(dayString[0], DATE_FORMAT),
            dayjs(dayString[1], DATE_FORMAT),
        ]);
        setFiltersDistribution((prev) => filterByDateRange(prev, dayString));
    };

    useEffect(() => {
        if (useIdentity?.userType === UserTypeEnum.TENANT) {
            commissionTableQueryResult.refetch();
        }
    }, [useIdentity?.id, filtersCommission]);

    useEffect(() => {
        if (useIdentity?.userType === UserTypeEnum.TENANT) {
            overviewTableQueryResult.refetch();
        }
    }, [useIdentity?.id, filtersOverview]);

    useEffect(() => {
        if (useIdentity?.userType === UserTypeEnum.TENANT) {
            distributionTableQueryResult.refetch();
        }
    }, [useIdentity?.id, filtersDistribution]);

    useEffect(() => {
        if (goalSetting?.data) {
            refetchTotalAmount();
            formSettingGoal.setFieldsValue({
                value: goalSetting.data.value / 100,
                goalType: goalSetting.data.goalType,
            });
        }
    }, [goalSetting]);

    useEffect(() => {
        if (goalSetting?.data?.value) {
            const amount = toTalAmount?.data?.totalAmount ?? 0;
            setGoalValue(((amount / goalSetting?.data.value) * 100).toFixed(2));
        }
    }, [goalSetting, toTalAmount]);

    const formatDataDistribution = dataDistribution?.dataSource?.length
        ? [...dataDistribution.dataSource].map((item) => {
              const formatData = {
                  name: translate(`reports.${item.name}`),
                  value: item.value,
              };

              return formatData;
          })
        : [];

    const saveGoal = (values: any) => {
        values.value = values.value * 100;
        mutate(
            {
                dataProviderName,
                resource: `${GOAL_RESOURCE_URL}`,
                values: values,
                successNotification: { message: 'Save goal successfully', type: 'success' },
            },
            {
                onError: (error, variables, context) => {
                    // An error happened!
                },
                onSuccess: (data) => {
                    if (data?.data?.data) {
                        setIsSettingGoal(false);
                        refetchGoalSetting();
                    }
                },
            },
        );
    };

    return (
        <>
            <div className="revenue-page-wrapper">
                {isSettingGoal ? (
                    <>
                        <div className="flex cursor-pointer">
                            <img
                                src="/images/icons/back-to-list.svg"
                                alt="back-to-list"
                                className="back-to-list mr-2"
                                onClick={() => {
                                    setIsSettingGoal(false);
                                }}
                            />
                            <p
                                onClick={() => {
                                    setIsSettingGoal(false);
                                }}
                                className="text-back"
                            >
                                {translate('marketplace.back')}
                            </p>
                        </div>
                        <Spin spinning={isSaveGoal}>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={16}>
                                    <Row gutter={[16, 16]} className="mt-4">
                                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                                            <h3 className="title-section title-goal-chart">
                                                {translate('reports.titleGoalSetting')}
                                            </h3>
                                            <Form
                                                form={formSettingGoal}
                                                name="edit-plan"
                                                layout="vertical"
                                                onFinish={saveGoal}
                                                autoComplete="off"
                                                key="2"
                                                className="mt-8"
                                            >
                                                <Form.Item
                                                    label={translate('reports.value_goal')}
                                                    name="value"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: translate(
                                                                'reports.input.value_goal_required',
                                                            ),
                                                        },
                                                    ]}
                                                >
                                                    <Input prefix="$" type="number" />
                                                </Form.Item>
                                                <Form.Item
                                                    label={translate('reports.type_goal')}
                                                    name="goalType"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: translate(
                                                                'reports.input._goal_required',
                                                            ),
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        placeholder={translate(
                                                            'reports.input.select_goal',
                                                        )}
                                                        showArrow
                                                        suffixIcon={
                                                            <img src="/images/icons/arrow-down-icon.svg" />
                                                        }
                                                        className="select-type-goal"
                                                    >
                                                        {OPTIONS_TYPE_GOAL.map((otp, index) => (
                                                            <Select.Option
                                                                key={index}
                                                                value={otp.value}
                                                            >
                                                                {otp.label}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                                <Button
                                                    className="btn-next mt-2"
                                                    type="primary"
                                                    htmlType="submit"
                                                >
                                                    {translate('buttons.save')}
                                                </Button>
                                            </Form>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                            <h3 className="title-section title-goal-chart text-center">
                                                {translate('reports.titleGoalChart')}
                                            </h3>
                                            <GoalCrmChart value={goalValue} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Spin>
                    </>
                ) : (
                    <>
                        <Row gutter={[32, 32]}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={16}>
                                {hasShowRevenuePermission && (
                                    <div className="overview-chart">
                                        <OverviewCrmChart
                                            title={translate('reports.titleOverviewChart')}
                                            data={dataOverview?.dataSource?.[0]}
                                            onChangeDateRange={onChangeDateRangeOverview}
                                            onChangeGranularity={onChangeGranularityOverview}
                                            valueDateRange={dateRangeOverall}
                                            valueGranularity={granularityOverall}
                                        />
                                    </div>
                                )}
                                <div className="revenue-chart">
                                    <LineCrmChart
                                        title={translate('reports.titleRevenueChart')}
                                        onChangeDateRange={onChangeDateRangeEarning}
                                        onChangeGranularity={onChangeGranularityEarning}
                                        data={
                                            dataEarning?.dataSource
                                                ? [...dataEarning.dataSource]
                                                : []
                                        }
                                        valueDateRange={dateRangeEarning}
                                        valueGranularity={granularityEarning}
                                    />
                                </div>
                                {hasShowRevenuePermission && (
                                    <div className="commission-chart">
                                        <LineCrmChart
                                            title={translate('reports.titleCommissionChart')}
                                            onChangeDateRange={onChangeDateRangeCommission}
                                            onChangeGranularity={onChangeGranularityCommission}
                                            data={
                                                dataCommission?.dataSource
                                                    ? [...dataCommission.dataSource]
                                                    : []
                                            }
                                            valueDateRange={dateRangeCommission}
                                            valueGranularity={granularityCommission}
                                        />
                                    </div>
                                )}
                                {hasShowRevenuePermission ? <OverdueInvoiceCrmIndex /> : null}
                                {hasShowRevenuePermission ? <IncomeCrmIndex /> : null}
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                                {hasShowRevenuePermission ? (
                                    <div className="overview-chart ">
                                        <PieCrmChart
                                            title={translate('reports.titleDistributionChart')}
                                            data={formatDataDistribution}
                                            onChangeDateRange={onChangeDateRangeOverall}
                                            valueDateRange={dateRangeDistribution}
                                        />
                                    </div>
                                ) : null}

                                <div className="goal-chart">
                                    <div className="flex items-center ">
                                        <h3 className="title-section mr-2  title-goal-chart">
                                            {translate('reports.titleGoalChart')}
                                        </h3>
                                        {hasCreateSettingGoalPermission ? (
                                            <div
                                                className="cursor-pointer"
                                                onClick={() => setIsSettingGoal(true)}
                                            >
                                                <PosSettingMenuSidebarIcon />
                                            </div>
                                        ) : null}
                                    </div>

                                    <GoalCrmChart value={goalValue} />
                                </div>
                            </Col>
                        </Row>
                    </>
                )}
            </div>
        </>
    );
};
