import './styles.scss';

import { PlusOutlined } from '@ant-design/icons';
import { ContactNoteItemForm } from '@components/contact-note/note-form';
import { UserStatusEnum } from '@enums/user-status.enum';
import { IContact } from '@interfaces/contact';
import { useModal } from '@refinedev/antd';
import { useApiUrl, useCustom } from '@refinedev/core';
import { convertPrice, formatPrice } from '@utils/resource';
import { Button, Empty, Modal } from 'antd';
import { DataProviderNameEnum } from 'dataProvider';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
    formatAppSubscriptionPeriodEnd,
    RECURRING_SUBSCRIPTION_SUBTRACT_DAY,
} from '../../../../utils/date';
import { ContactCrmNoteList } from '../contact-note/note-list';

export const ContactCrmInsights: React.FC<{
    contactId: string;
    contactStatus?: UserStatusEnum;
    userId?: string;
}> = ({ contactId }) => {
    const { t } = useTranslation(['quote', 'common', 'contact']);

    const apiUrl = useApiUrl(DataProviderNameEnum.INTERNAL_CRM);

    const dataProviderName = DataProviderNameEnum.INTERNAL_CRM;

    const { data: insightsData } = useCustom<IContact.IInvoicesOfContactDto>({
        dataProviderName,
        url: `${apiUrl}/v1/contacts/invoices-contact/${contactId}`,
        method: 'get',
    });
    const { data: latestExpirationSubscription } = useCustom<any>({
        dataProviderName,
        url: `${apiUrl}/v1/application-subscriptions/invoices/latest-expiration/${contactId}`,
        method: 'get',
    });

    // Show modal and Add new note for contact
    const { modalProps: modalNoteProps, show: showModalNote, close: closeModalNote } = useModal();
    const handleAddNewNote = () => {
        showModalNote();
    };

    return (
        <>
            <div className="flex flex-col">
                <div className="mb-4">
                    <div className="mb-2 font-bold title">
                        {t('contact.insight', { ns: 'common' })}
                    </div>
                    <div className="contact-insight contact-total-insight">
                        <h4>{t('contact.total_spend', { ns: 'common' })}</h4>
                        <h3 className="mb-2">{formatPrice(insightsData?.data.sumTransaction)}</h3>
                        <span className="text-gray-400">
                            {insightsData?.data.totalTransaction}{' '}
                            {insightsData?.data.totalTransaction &&
                            insightsData.data.totalTransaction > 1
                                ? t('contact.transactions', { ns: 'common' })
                                : t('contact.transaction', { ns: 'common' })}
                        </span>
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="mb-2 font-bold title">
                        {t('contact.subscription_invoice', { ns: 'common' })}
                    </div>

                    <div className="contact-insight p-5">
                        {latestExpirationSubscription?.data.data ? (
                            <div className="note-list-wrapper last-expiration-subscription ">
                                <span>
                                    {t('contact.current_invoice', { ns: 'common' })}:
                                    <Link
                                        to={`/sale/invoices/show/${latestExpirationSubscription?.data.data.invoiceId}`}
                                        className="table-tbody-text"
                                    >
                                        <u>
                                            {' '}
                                            #{' '}
                                            {
                                                latestExpirationSubscription?.data.data
                                                    .invoiceIncrementId
                                            }
                                        </u>
                                    </Link>
                                </span>
                                <span>
                                    {t('contact.product_name', { ns: 'common' })}:{' '}
                                    {latestExpirationSubscription?.data.data.applicationTitle ??
                                        ' - -'}
                                </span>
                                <span>
                                    {t('contact.billing_monthly', { ns: 'common' })}{' '}
                                    {formatAppSubscriptionPeriodEnd(
                                        RECURRING_SUBSCRIPTION_SUBTRACT_DAY,
                                        latestExpirationSubscription?.data.data.periodEnd as Date,
                                    )}
                                </span>
                                <span>
                                    {t('contact.price', { ns: 'common' })}:{' '}
                                    {convertPrice(latestExpirationSubscription?.data.data.amount)}
                                </span>
                            </div>
                        ) : (
                            <div className="note-list-wrapper text-center last-expiration-subscription">
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </div>
                        )}
                    </div>
                </div>
                <div className="subscription">
                    <div className="flex flex-row justify-between items-center">
                        <div className="my-4 font-bold title">
                            {t('contacts.note', { ns: 'contact' })}
                        </div>
                        <Button
                            onClick={() => handleAddNewNote()}
                            className="contact-button"
                            type="text"
                            icon={<PlusOutlined />}
                        >
                            {t('add_new', { ns: 'common' })}
                        </Button>
                    </div>
                    {contactId && <ContactCrmNoteList contactId={contactId} />}
                </div>

                <Modal
                    {...modalNoteProps}
                    title={t('contacts.note_content.add_new', { ns: 'contact' })}
                    maskClosable={false}
                    footer={null}
                    width={650}
                    wrapClassName="payment-modal"
                >
                    <div className="mt-2">
                        <ContactNoteItemForm
                            contactId={contactId}
                            onCancelEdit={() => {
                                closeModalNote();
                            }}
                            onUpdate={() => {
                                closeModalNote();
                            }}
                        />
                    </div>
                </Modal>
            </div>
        </>
    );
};
